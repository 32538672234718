import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  getAdminEventSubPromoterLinkV2,
  createAdminEventSubPromoterLinkV2,
} from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import Loader from "../../Components/Loader";
import Header from "../../Components/Header";
import { IAdminEventDetailsIn } from "../../interfaces/events/adminEventDetails";
import { IAdminEventSubpromotersIn } from "../../interfaces/events/adminEventDetails";
import SubPromoterLinksV2 from "./components/SubPromoterLinksV2/SubPromoterLinksV2";

type Props = {
  user: any;
};


const EventSubpromoterDetails: React.FC<Props> = ({ user }) => {
  const [event, setEvent] = React.useState<IAdminEventSubpromotersIn>();
  const [loading, setLoading] = React.useState(true);
  const [subpromotors, setSubpromotors] = React.useState<any[]>([]);

  useEffect(() => {
    getAdminEventSubPromoterLinkV2(window.location.pathname?.split("/").slice(-2, -1)[0] as string)
      .then((data) => {
        setStateFromNewData(data);
        setSubpromotors(data.event?.sub_promoters);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleCreateSubPromoter = (title: string) => {
    createAdminEventSubPromoterLinkV2(
      event?.event?.id as unknown as string,
      title
    )
      .then((data: any) => {
        console.log(data);
        let temp = [...subpromotors];
        temp.push(data);
        setSubpromotors(temp.sort((a, b) => a.title - b.title));
      })
      .catch((err: any) => {
        alert("User not found");
      });
  };

  const setStateFromNewData = (data: IAdminEventDetailsIn) => {
    setEvent(data);
    setLoading(false);
  };

  return (
    <AdminLayout title={event?.event?.title || 'Loading subpromoter links...'}>
      <div className="BloxAdminContentContainer BloxAdminEventDetails">
        <Loader loading={loading} color="#000" />
        {/* <Row>
          <Col>
            <Header content="Event subpromoters overview" />
            {event && (
              <Row
                className="BloxAdminEventDetailsHeader"
                style={{ backgroundColor: "#000" }}
              >
                <EventPicAndDetail event={event} />
                <Col
                  xs={12}
                  md={8}
                  className="rightColumn"
                  style={{ alignSelf: "center" }}
                >
                  <Row>
                    <Col>
                      <MetricsOptions user={user} event={event} />
                      <GeneralMetrics
                        event={event}
                        genderIcon={<FaGenderless />}
                      />
                      <TraficMetrics event={event} />
                      <EventMap event={event} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row> */}

        {/* {event && event?.sales?.by_inventory?.length > 0 && (
          <Row>
            <Col>
              <Header content="Sales analytics" />
              {event && event?.sales?.by_inventory?.length > 0 && (
                <EventAnalyticsPanel event={event} />
              )}
            </Col>
          </Row>
        )} */}


        {/* {event && (
            <Row>
              <Col>
                <Header content="Promo code uses" />
                <PromoCodeMetrics event={event} />
              </Col>
            </Row>
        )} */}

       {/* {event && (
          <Row>
            <Col>
              <Header content="Recent Orders" />
              <RecentOrders event={event} />
            </Col>
          </Row>
        )}  */}

        {event && (
          <Row>
            <Col style={{marginTop: '-5px'}}>
              <Header content={<div style={{display: 'flex', gap: '0.5rem'}}>Subpromoter Links <div style={{fontSize: '8px', fontWeight: 700, backgroundColor: '#cacaca', color: 'black', padding: '2px', textTransform: 'uppercase', borderRadius: '4px', alignSelf: 'flex-start', marginTop: '2px'}}>Beta</div></div>} />
              <SubPromoterLinksV2
                event={event}
                subPromoters={subpromotors}
                handleCreateSubPromoter={handleCreateSubPromoter}
              />
            </Col>
          </Row>
        )}

        {/* {event && (
          <Row>
            <Col>
              <Header content="Marketing Data" />
              <div style={{ width: "100%", textAlign: "center" }}>
                <LoadingButton
                  style={{ width: "50%", background: "#17a2b8" }}
                  variant="contained"
                  color="info"
                  loading={loadingDownloadCSV}
                  onClick={(e: any) => {
                    downloadCSVFile(e, event);
                  }}
                >
                  Email List
                </LoadingButton>
                <CSVLink
                  headers={headers}
                  filename="Blox_Email_Export.csv"
                  data={emailList}
                  ref={csvLinkEl}
                />
                <p
                  style={{
                    fontSize: "13px",
                    color: "#999999",
                    marginTop: "10px",
                  }}
                >
                  Click the button above to download an email list of all users
                  that placed an order for this event. You can import this CSV
                  file into Google Docs or Sendy to manage your own customer
                  mailing list or order history.
                </p>
              </div>
            </Col>
          </Row>
        )} */}
      </div>
    </AdminLayout>
  );
};

export default EventSubpromoterDetails;
