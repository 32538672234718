/* eslint-disable no-alert, no-console */
/* eslint-disable */

import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NavBar from "../Components/NavBar/NavBar";
import { Helmet } from "react-helmet";
import AppContainer from "../App/AppContainer";
import Home from "../Routes/Home";
import About from "../Routes/About";
import PasswordReset from "../Routes/PasswordReset";
import SignInRequired from "../Routes/SignInRequired";
import ProfileChange from "../Routes/ProfileChange";
import PasswordChange from "../Routes/PasswordChange";
import EventCalendar from "../Routes/EventCalendar";
import EventPurchase from "../Routes/EventPurchase";
import PurchaseReceipt from "../Routes/PurchaseReceipt";
import ArtistList from "../Routes/ArtistList";
import ArtistDetail from "../Routes/ArtistDetail";
import ContactUs from "../Routes/ContactUs";
import TableReservations from "../Routes/TableReservations";
import Page from "../Routes/Page";
import PressRelease from "../Routes/PressRelease";
import Orders from "../Routes/Orders/Orders";
import OrderDetail from "../Routes/OrderDetail/OrderDetail";
import TicketDetail from "../Routes/TicketDetail";
import FanCampaign from "../Routes/FanCampaign";
import RouteNotFound from "../Routes/RouteNotFound";
import ClientOnboarding from "../Routes/ClientOnboarding";
import EventProviderAgreement from "../Routes/EventProviderAgreement";
import Explore from "../Routes/Explore";
import Gallery from "../Routes/Gallery";
import EventGallery from "../Routes/EventGallery";
import Homepage from "../Routes/Homepage";
import FlashAlert from "../Components/FlashAlert";
import { Row, Col } from "reactstrap";

import Dashboard from "../RoutesAdmin/Dashboard/Dashboard";
import WhiteLabelChange from "../RoutesAdmin/WhiteLabelChange/WhiteLabelChange";
import EventList from "../RoutesAdmin/EventList/EventList";
import EventAdd from "../RoutesAdmin/EventAdd/EventAdd";
import EventChange from "../RoutesAdmin/EventChange/EventChange";
import EventDuplicate from "../RoutesAdmin/EventDuplicate/EventDuplicate";
import EventDetails from "../RoutesAdmin/EventDetails/EventDetails";
import EventGuestListNew from "../RoutesAdmin/EventGuestListNew/EventGuestListNew";
import TicketInventoryAdd from "../RoutesAdmin/TicketInventoryAdd/TicketInventoryAdd";
import TicketInventoryChange from "../RoutesAdmin/TicketInventoryChange/TicketInventoryChange";
import PromoCodeAdd from "../RoutesAdmin/PromoCodeAdd/PromoCodeAdd";
import PromoCodeChange from "../RoutesAdmin/PromoCodeChange/PromoCodeChange";
import VenueList from "../RoutesAdmin/VenueList/VenueList";
import VenueAdd from "../RoutesAdmin/VenueAdd/VenueAdd";
import OrderList from "../RoutesAdmin/OrderList/OrderList";
import UserList from "../RoutesAdmin/UserList/UserList";
import UserDetails from "../RoutesAdmin/UserDetails/UserDetails";
import OrderDetails from "../RoutesAdmin/OrderDetails/OrderDetails";
import PromoCodeList from "../RoutesAdmin/PromoCodeList/PromoCodeList";
import Tickets from "../Routes/Tickets/Tickets";
import "../Stylesheets/main.scss";
import OrderByEvent from "../RoutesAdmin/OrderByEvent/OrderByEvent";
import routes from "../constants/routes";
import Events from "../Routes/Events";
import EventPromoterDetails from "../RoutesAdmin/EventPromoterDetails/EventPromoterDetails";
import StaffManagement from "../RoutesAdmin/StaffManagement/StaffManagement";
import {EventGuestListV2} from "../RoutesAdmin/EventGuestListV2/EventGuestListV2";
import EventSubpromoterDetails from "../RoutesAdmin/EventDetails/EventSubpromoterDetails";

type Props = {
  whiteLabel: any;
  user: any;
  postSignIn: any;
  postSignUp: any;
  postSignOut: any;
  postPasswordForgot: any;
  signedIn: any;
  handleGetUser: any;
  userExists: any;
  userHasAdminAccess: any;
  postProfileChange: any;
  postPasswordChange: any;
  alert: any;
};

const AppStackNavigator: React.FC<Props> = ({
  whiteLabel,
  user,
  postSignIn,
  postSignUp,
  postSignOut,
  postPasswordForgot,
  signedIn,
  handleGetUser,
  userExists,
  userHasAdminAccess,
  postProfileChange,
  postPasswordChange,
  alert,
}) => {
  return (
    <BrowserRouter>
      <AppContainer whiteLabel={whiteLabel}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{whiteLabel.title}</title>
          <link rel="canonical" href="http://www.thebloxoffice.com" />
          {whiteLabel.id === 2 && (
            <script>{/* {injectPrimaryGA()} UNCOMMENT THIS */}</script>
          )}
          {whiteLabel.id === 2 && (
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-210037124-1"
            ></script>
          )}
        </Helmet>

        {/* MENU/NAVIGATION BAR */}
        <NavBar
          user={user}
          postSignIn={postSignIn}
          postSignUp={postSignUp}
          postSignOut={postSignOut}
          postPasswordForgot={postPasswordForgot}
          whiteLabel={whiteLabel}
        />

        {/* GLOBAL ALERTS */}
        {alert.message && (
          <Row>
            <Col>
              <FlashAlert color={alert.type} text={alert.message} />
            </Col>
          </Row>
        )}

        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<Home whiteLabel={whiteLabel} />} />

          <Route
            path="/reset-password/:uid/:token"
            element={<PasswordReset />}
          />

          <Route path="/about" element={<About whiteLabel={whiteLabel} />} />

          <Route
            path="/homepage"
            element={<Homepage whiteLabel={whiteLabel} />}
          />

          <Route path="/events" element={<About whiteLabel={whiteLabel} />} />

{/*           <Route
            path="/events"
            element={
              <Events
                signedIn={signedIn}
                postSignIn={postSignIn}
                postSignUp={postSignUp}
                postPasswordForgot={postPasswordForgot}
                whiteLabel={whiteLabel}
              />
            }
          /> */}

          <Route
            path="/events/calendar"
            element={<EventCalendar whiteLabel={whiteLabel} />}
          />

          <Route
            path="/events/:id"
            element={
              <Events
                signedIn={signedIn}
                postSignIn={postSignIn}
                postSignUp={postSignUp}
                postPasswordForgot={postPasswordForgot}
                whiteLabel={whiteLabel}
              />
            }
          />

          <Route
            path="/events/:id/:promoterId"
            element={
              <Events
                signedIn={signedIn}
                postSignIn={postSignIn}
                postSignUp={postSignUp}
                postPasswordForgot={postPasswordForgot}
                whiteLabel={whiteLabel}
              />
            }
          />

          <Route
            path="/artists"
            element={<ArtistList whiteLabel={whiteLabel} />}
          />

          <Route path="/artists/:slug" element={<ArtistDetail />} />

          <Route path="/page/:slug" element={<Page />} />

          <Route
            path="/press/:id"
            element={<PressRelease whiteLabel={whiteLabel} />}
          />

          <Route path="/explore" element={<Explore />} />

          <Route
            path="/gallery"
            element={<Gallery whiteLabel={whiteLabel} />}
          />

          <Route
            path="/event-gallery/:id"
            element={<EventGallery whiteLabel={whiteLabel} />}
          />

          <Route
            path="/contact-us/"
            element={<ContactUs user={user} whiteLabel={whiteLabel} />}
          />

          <Route
            path="/table-reservations/"
            element={<TableReservations user={user} whiteLabel={whiteLabel} />}
          />

          <Route
            path="/onboarding/:id"
            element={
              <ClientOnboarding
                closeNavbar={() => {}}
                postSignIn={postSignIn}
                postSignUp={postSignUp}
                postPasswordForgot={postPasswordForgot}
                user={user}
                getUser={handleGetUser}
              />
            }
          />

          <Route
            path="/onboarding"
            element={
              <ClientOnboarding
                closeNavbar={() => {}}
                postSignIn={postSignIn}
                postSignUp={postSignUp}
                postPasswordForgot={postPasswordForgot}
                user={user}
                getUser={handleGetUser}
              />
            }
          />

          <Route
            path="/onboarding-terms"
            element={<EventProviderAgreement />}
          />

          <Route
            path="/events/:id/purchase"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <EventPurchase
                  eventDetails={""}
                  user={user}
                  getUser={handleGetUser}
                  whiteLabel={whiteLabel}
                />
              )
            }
          />

          <Route
            path="/events/:id/purchase/:orderId"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <EventPurchase
                  eventDetails={""}
                  user={user}
                  getUser={handleGetUser}
                  whiteLabel={whiteLabel}
                />
              )
            }
          />

          <Route
            path="/events/:id/purchase/:orderId/:subpromoterId"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <EventPurchase
                  eventDetails={""}
                  user={user}
                  getUser={handleGetUser}
                  whiteLabel={whiteLabel}
                />
              )
            }
          />

          <Route
            path="/purchase/receipt/:orderId"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <PurchaseReceipt
                  eventDetails={""}
                  signedIn={signedIn}
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              )
            }
          />

          <Route
            path="/orders"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <Orders
                  signedIn={signedIn}
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                  user={user}
                />
              )
            }
          />

          <Route
            path="/orders/:id"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <OrderDetail />
              )
            }
          />

          <Route
            path="/tickets"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (            
              <Tickets user={user} />
              )
            }
          />

          <Route
            path="/tickets/:id"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postSignUp={postSignUp}
                  postPasswordForgot={postPasswordForgot}
                />
              ) : (
                <TicketDetail user={user} />
              )
            }
          />

          <Route
            path="/change-profile"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <ProfileChange
                  user={user}
                  postProfileChange={postProfileChange}
                />
              )
            }
          />

          <Route
            path="/change-password"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <PasswordChange postPasswordChange={postPasswordChange} />
              )
            }
          />

          <Route
            path="/fan-campaign/:campaignId/:eventId/:orderId"
            element={
              !userExists() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <FanCampaign />
              )
            }
          />

          <Route
            path="/admin"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <Dashboard user={user} />
              )
            }
          />

          <Route
            path="/admin/events"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventList eventType="Upcoming" user={user} />
              )
            }
          />

          <Route
            path="/admin/events/upcoming"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventList eventType="Upcoming" user={user} />
              )
            }
          />

          <Route
            path="/admin/staff"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <StaffManagement user={user} />
              )
            }
          />

          <Route
            path="/admin/events/past"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventList eventType="Past" user={user} />
              )
            }
          />

{/*           <Route
            path="/admin/site/edit/"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <WhiteLabelChange user={user} whiteLabel={whiteLabel} />
              )
            }
          /> */}

          <Route
            path="/admin/events/add"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventAdd user={user} />
              )
            }
          />

          <Route
            path="/admin/events/edit/:eventId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventChange user={user} />
              )
            }
          />

          <Route
            path="/admin/events/duplicate/:eventId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventDuplicate user={user} />
              )
            }
          />

          <Route
            path="/admin/events/:eventId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventDetails user={user} />
              )
            }
          />
          
          <Route
            path="/admin/events/:eventId/subpromoters"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventSubpromoterDetails user={user} />
              )
            }
          />

          <Route
            path="/admin/events/:eventId/subpromoters/:subpromoterId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventPromoterDetails user={user} />
              )
            }
          />

          <Route
            path="/admin/events/:eventId/subpromoters"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventSubpromoterDetails user={user} />
              )
            }
          />

          <Route
            path="/admin/events/ticket-inventories/add"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <TicketInventoryAdd user={user} />
              )
            }
          />

          <Route
            path="/admin/events/ticket-inventories/add/:eventId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <TicketInventoryAdd user={user} />
              )
            }
          />

          <Route
            path="/admin/events/ticket-inventories/edit/:ticketInventoryId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <TicketInventoryChange user={user} />
              )
            }
          />

          <Route
            path="/admin/promo-codes/"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <PromoCodeList />
              )
            }
          />

          <Route
            path="/admin/promo-codes/add/"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <PromoCodeAdd user={user} />
              )
            }
          />

          <Route
            path="/admin/promo-codes/edit/:promoCodeId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <PromoCodeChange user={user} />
              )
            }
          />

          <Route
            path="/admin/events/guestlist/:eventId"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventGuestListNew user={user} />
              )
            }
          />
          
          <Route
            path="/admin/events/guestlist/:eventId/v2"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <EventGuestListV2 user={user} />
              )
            }
          />

          <Route
            path="/admin/venues/"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <VenueList />
              )
            }
          />

          <Route
            path="/admin/venues/add"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <VenueAdd />
              )
            }
          />

          <Route
            path="/admin/orders/"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <OrderList />
              )
            }
          />

          <Route
            path="/admin/orders/:id"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <OrderDetails />
              )
            }
          />

          <Route
            path={`${routes.ADMIN_ORDERS_BY_EVENT}/:eventId`}
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <OrderByEvent />
              )
            }
          />

          <Route
            path="/admin/users/"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <UserList />
              )
            }
          />

          <Route
            path="/admin/users/:id"
            element={
              !userHasAdminAccess() ? (
                <SignInRequired
                  postSignIn={postSignIn}
                  postPasswordForgot={postPasswordForgot}
                  postSignUp={postSignUp}
                />
              ) : (
                <UserDetails />
              )
            }
          />

          <Route
            path="/events/uQCj6oP4"
            element={<Navigate to="/events/582" replace />}
          />

          <Route
            path="/events/ZlCGw2PT"
            element={<Navigate to="/events/559" replace />}
          />
          <Route
            path="/events/z0CayxPA"
            element={<Navigate to="/events/579" replace />}
          />
          <Route
            path="/events/E1ChnBPU"
            element={<Navigate to="/events/555" replace />}
          />
          <Route
            path="/events/A9CmZhPH"
            element={<Navigate to="/events/580" replace />}
          />
          <Route
            path="/events/H3CKDRPG"
            element={<Navigate to="/events/583" replace />}
          />
          <Route
            path="/events/b0CQsgPO"
            element={<Navigate to="/events/557" replace />}
          />
          <Route
            path="/events/ZJCRhGPu"
            element={<Navigate to="/events/578" replace />}
          />
          <Route
            path="/events/4WCOVFP6"
            element={<Navigate to="/events/1" replace />}
          />
          <Route
            path="/events/GgCdk7Pq"
            element={<Navigate to="/events/588" replace />}
          />
          <Route
            path="/events/ItCtUKPo"
            element={<Navigate to="/events575" replace />}
          />
          <Route
            path="/events/IGCocOP4"
            element={<Navigate to="/events/590" replace />}
          />
          <Route
            path="/events/tDCW6kPE"
            element={<Navigate to="/events/560" replace />}
          />
          <Route
            path="/events/wUCZ49P7"
            element={<Navigate to="/events/576" replace />}
          />
          <Route
            path="/events/ZUCAKfPz"
            element={<Navigate to="/events/589" replace />}
          />
          <Route
            path="/events/bwCuURPV"
            element={<Navigate to="/events/571" replace />}
          />
          <Route
            path="/events/ypCTQsPb"
            element={<Navigate to="/events/573" replace />}
          />

          <Route element={<RouteNotFound />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

export default AppStackNavigator;
