import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { getOrdersByEvent } from "../../../../Lib/RESTBlox";
import { useMediaQuery } from "react-responsive";

type Props = {
  event: any;
};

const RecentOrders: React.FC<Props> = ({ event }) => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 560px)" });

  useEffect(() => {
    if (event.event.id) {
      getOrdersByEvent(event.event.id)
        .then((res) => {
          setOrders(res.results.slice(0,5) as any)
        })
        .catch((e) => {
          alert(e.message);
        });
    }
  }, [event.event.id]);

  const getDate = (date: string) => {
    const actual = new Date(date);
    const chicagoTime = new Date(actual.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const options: Intl.DateTimeFormatOptions = { month: 'numeric', day: 'numeric' };
    return chicagoTime.toLocaleDateString('en-US', options);
  };

  const getTime = (date: string) => {
    const actual = new Date(date);
    const chicagoTime = new Date(actual.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    return chicagoTime.toLocaleTimeString('en-US', options);
  };

  return (
    <>
      <div style={{maxWidth: '100%', overflowX: 'auto', marginBottom: '1rem'}}>
        <table className="tickets-table">
          <thead>
            <tr>
              {!isMobile && (
                <th className="recent-orders-tickets-name">Details</th>
              )}
              <th className="recent-orders-time-name">Time</th>
              <th className="recent-orders-purchaser-name">Purchaser</th>
              <th className="recent-orders-total-name">Total</th>
              <th className="recent-orders-actions-name">Order Number</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order: any, index: number) => (
              <tr className="recent-orders-table-row">

                {!isMobile && (
                  <td className="recent-orders-details">
                    <p className="name-and-qty">
                      {order?.event?.title ?? ""}
                    </p>
                    <p className="secondary-span status">
                      {order?.status} ({order?.was_charged ? "charged" : "pending"})
                    </p>
                  </td>
                )}

                <td className="recent-orders-time">
                  <span>{getDate(order.date_created)}</span>
                  <br />
                  <span className="secondary-span">{getTime(order.date_created)}</span>
                </td>

                <td className="recent-orders-purchaser">
                  <span>
                    <Link
                      style={{ color: "black" }}
                      to={"/admin/users/" + order.purchaser.pk}
                    >
                      {order.purchaser.first_name} {order.purchaser.last_name}
                    </Link>
                  </span>
                  <br />
                  <span className="secondary-span">
                    {order.purchaser.email}
                  </span>
                </td>

                <td className="recent-orders-total">{order.total_str}</td>

                <td className="recent-orders-actions">
                  <Link
                    style={{
                      margin: "5px",
                      padding: "3px",
                    }}
                    to={`/admin/orders/${order.id}`}
                  >
                    {order.number}
                  </Link>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          style={{ width: "50%", background: "#17a2b8" }}
          variant="contained"
          onClick={(e: any) => {
            navigate("/admin/orders/events/" + event?.event?.id);
          }}
        >
          View All
        </Button>
      </div>
    </>
  );
};

export default RecentOrders;
